
:root {
  --background-color:#1677ff;
  --color:#1677ff;
}


.page-principale {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.contextes {
  flex-direction: column;
  align-items: left;
  margin-left: 20px;
  justify-content: center;
  color: #1677ff;
}

.menu-clerk {
  margin-top: 20px;
  margin-left: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1677ff;
}





/*.site-layout {
    background-color: #8d8989;
  }*/

.trigger {
  color: #1677ff;
}

.terminal {
  height: 100%;
  width: 100%;
  padding-left: 10px;
  border-top: 1px;
  border-top-width: 2px;
  border-top-color: #1677ff;

  overflow-y: auto;
  font-size: 14px;
  line-height: 1.42;
  font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier,
    monospace;
  /*text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);*/
}

.terminal__line {
  line-height: 2;
  white-space: pre-wrap;
}

.terminal__prompt {
  display: flex;
  /*align-items: center;*/
}

.terminal__prompt__label {
  flex: 0 0 auto;
  color: #1677ff;
}

.terminal__prompt__input {
  flex: 1;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  color: white;
}

.terminal__prompt__input textarea {
  display: inline-block;
  flex: 1;
  width: 100%;
  background-color: transparent;
  border: 0;
  outline: none;
  font-size: 14px;
  line-height: 1.42;
  font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier,
    monospace;
  word-wrap: break-word;
}

.cursor-terminal {
  color: #1677ff;
}

.align-textarea {
  vertical-align: top;
  color: #1677ff;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}


.site-layout {
  height: 100vh;
}


.liste-bavardages {
  min-height: 100px;
}

.list-bavardages-item .ant-list-item-action {
  display: flex;
  justify-content: center;
}